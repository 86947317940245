<template>
  <div>
    <div class="search-bar">
      <template>
        <b-button variant="primary" @click="goBack">
          <img src="./icons/chevron-left.svg" alt="" />
          Назад к списку
        </b-button>
        <b-form-input type="search" v-model="search" placeholder="Что ищете?" />
      </template>
    </div>

    <div class="questions">
      <div
        v-for="(question, index) in filteredQuestions"
        :key="question.id"
        :title="question.question"
        class="questions__item"
      >
        <div class="questions__item__question">
          <button type="button" class="btn btn-secondary">
            <img
              v-if="openQuestions[question.id]"
              src="../monitoring-snp/icons/open.svg"
              alt=""
              @click="handleClose(question)"
            />
            <img
              v-else
              src="../monitoring-snp/icons/closed.svg"
              alt=""
              @click="handleOpen(question)"
            />
          </button>
          <span class="question-title"
            >{{ index + 1 }}.
            {{ question[`question${getLang(i18nService.locale)}`] }}
            <span class="module-name">
              ({{ question.moduleNameRu || openedModule.name }})
            </span></span
          >

          <div
            class="video-link"
            v-if="
              question[`videoLink${getLang(i18nService.locale)}`] &&
              question[`videoLink${getLang(i18nService.locale)}`].includes(
                'http'
              )
            "
            @click="
              handleOpenVideo(
                question[`videoLink${getLang(i18nService.locale)}`]
              )
            "
          >
            <img src="./icons/video-link.svg" alt="" />
          </div>

          <div
            class="edit-icon"
            @click="getQuestionByid(question)"
            v-if="roles.ADMIN"
          >
            <img src="./icons/edit.svg" alt="" />
          </div>
        </div>

        <div v-if="openQuestions[question.id]" class="questions__item__answer">
          {{ question[`answer${getLang(i18nService.locale)}`] }}
        </div>
      </div>
    </div>
    <b-modal
      v-model="isQuestionModalOpen"
      @hide="handleModalClose"
      size="lg"
      centered
      hide-header
      hide-footer
    >
      <div class="modal__title">Редактировать</div>
      <div class="modal__card">
        <template v-if="selectedQuestion">
          <div class="card__row">
            <div class="row__label">Вопрос</div>
            <textarea
              class="row__textarea"
              v-model="selectedQuestion.questionRu"
            />
          </div>
          <div class="card__row">
            <div class="row__label">Ответ</div>
            <textarea
              class="row__textarea"
              v-model="selectedQuestion.answerRu"
            />
          </div>
          <div class="card__row">
            <div class="row__label">Вопрос (на казахском)</div>
            <textarea
              class="row__textarea"
              v-model="selectedQuestion.questionKz"
            />
          </div>
          <div class="card__row">
            <div class="row__label">Ответ (на казахском)</div>
            <textarea
              class="row__textarea"
              v-model="selectedQuestion.answerKz"
            />
          </div>
        </template>

        <div v-if="selectedQuestion" class="checkbox-container single">
          <b-checkbox v-model="selectedQuestion.isTopQuestion"></b-checkbox>
          Добавить в ТОП вопросов
        </div>

        <template v-if="selectedQuestion">
          <div class="card__row video-row">
            <input
              type="text"
              class="row__input"
              placeholder="Ссылка на видеоинструкцию"
              v-model="selectedQuestion.videoLinkRu"
            />
          </div>
          <div class="card__row video-row">
            <input
              type="text"
              class="row__input"
              placeholder="Ссылка на видеоинструкцию (на казахском)"
              v-model="selectedQuestion.videoLinkKz"
            />
          </div>
        </template>
      </div>

      <div class="modal__footer">
        <b-btn variant="success" @click="handleSave">Сохранить</b-btn>
        <b-btn variant="secondary" @click="handleModalClose"> Отменить </b-btn>
        <b-btn variant="danger" @click="handleDeleteQuestion"> Удалить </b-btn>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import { MonitoringSnpApi } from "../monitoring-snp/api/MonitoringSnpApi";
import i18nService from "@/services/i18n";

export default {
  name: "SelectedModule",
  props: ["openedModule", "roles"],

  data() {
    return {
      questions: [],
      openQuestions: {},
      search: "",
      selectedLang: "Ru",
      i18nService,
      selectedQuestion: null,
      isQuestionModalOpen: false,
    };
  },
  watch: {
    questions: {
      handler() {
        this.mapQuestionsToOpenStatus();
      },
      immediate: true,
      deep: true,
    },
    openedModule: {
      handler(newVal) {
        if (newVal) {
          this.getQuestions(newVal.id);
        }
      },
      immediate: true,
    },
  },
  computed: {
    filteredQuestions() {
      return this.questions.filter((question) =>
        question[`question${this.selectedLang}`]
          .toLowerCase()
          .includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    mapQuestionsToOpenStatus() {
      this.openQuestions = this.questions.reduce((acc, question) => {
        acc[question.id] = false;
        return acc;
      }, {});
    },
    goBack() {
      this.$emit("update:openedModule", null);
    },
    handleClose(item) {
      this.openQuestions[item.id] = false;
    },
    handleOpen(item) {
      this.openQuestions[item.id] = true;
    },
    ...mapMutations({
      setVideoPlayerState: "setVideoPlayerState",
    }),
    embedUrl(link) {
      const url = new URL(link);
      const videoId = url.searchParams.get("v");
      return `https://www.youtube.com/embed/${videoId}`;
    },
    handleOpenVideo(link) {
      this.setVideoPlayerState({
        isOpen: true,
        src: link,
        embedSrc: this.embedUrl(link),
        name: "Video name",
      });
    },
    getQuestions(id) {
      const val = id ? `module/${id}` : "top";

      MonitoringSnpApi.get(`/api/guide/faq/${val}`).then((res) => {
        this.questions = res.data;
      });
    },
    getQuestionByid(question) {
      MonitoringSnpApi.get(`/api/guide/faq/${question.id}`).then((res) => {
        this.selectedQuestion = res.data;
        this.isQuestionModalOpen = true;
      });
    },
    handleDeleteQuestion() {
      MonitoringSnpApi.delete(
        `/api/guide/faq/${this.selectedQuestion.id}`
      ).then(() => {
        this.getQuestions(this.openedModule.id);
        this.isQuestionModalOpen = false;
      });
    },
    handleSave() {
      MonitoringSnpApi.put(`/api/guide/faq/${this.selectedQuestion.id}`, {
        ...this.selectedQuestion,
      }).then(() => {
        this.getQuestions(this.openedModule.id);
        this.isQuestionModalOpen = false;
      });
    },
    getLang(str) {
      return str === "ru" ? "Ru" : "Kz";
    },
  },
  mounted() {
    this.mapQuestionsToOpenStatus();
  },
};
</script>

<style lang="scss" scoped>
.search-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 20px;

  .btn {
    width: fit-content;
    white-space: nowrap;
    gap: 5px;
    align-items: center;
    padding: 0 15px;
  }
}

.questions {
  &__item {
    min-height: 50px;
    &__question {
      background: #d4fae6;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      gap: 10px;
      border-bottom: 1px solid #416180;
      height: 50px;
      width: 100%;
    }

    &__answer {
      padding: 15px 20px;
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.question-title {
  font-size: 16px;
  font-weight: 700;
}

.module-name {
  font-size: 16px;
  font-weight: 500;
}

.video-link {
  cursor: pointer;
  img {
    height: 38px;
  }
}

.edit-icon {
  cursor: pointer;
  justify-self: flex-end;
  margin-left: auto;
}

.modal {
  &__card {
    margin-top: 15px;
    background-color: #f7f9fc;
    border-radius: 5px;
    padding: 15px 20px 0;
    border: 1px solid #e2e5eb;
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 1.4;
  }

  &__toggle {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 15px;
  }

  &__file {
    display: flex;
    gap: 20px;
    align-items: center;
    font-size: 14px;
    color: #6087a0;
  }

  &__upload {
    padding: 0 20px;
    display: flex;
    align-items: center;
    gap: 13px;

    & img {
      width: 10px;
      height: 18px;
    }

    & a {
      font-size: 14px;
      color: #6087a0;
      text-decoration: underline;
    }
  }

  &__footer {
    padding-top: 15px;
    display: flex;
    gap: 20px;

    button {
      margin-left: 0;
    }
  }
}

.card {
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 0;

    :has(> textarea) {
      align-items: flex-start;
    }

    & > *:last-child {
      width: 440px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #41618033;
    }
  }
}

.row {
  &__textarea {
    height: 110px;
    resize: none;
  }

  &__input {
    border: 1px solid #bcd2e0;
    border-radius: 4px;
    font-size: 14px;
    padding: 4px 8px;
  }
}

.file {
  &__delete {
    cursor: pointer;
    color: #6087a0;
    display: flex;
    gap: 10px;
  }
}

.orange-bg {
  background: rgba(247, 150, 71, 1);
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 700;
}

.contact {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 500;

  &__item {
    background: rgba(255, 255, 255, 1);
    border-radius: 5px;
    border: 1px solid rgba(224, 130, 54, 1);
    padding: 0px 10px;
    color: rgba(28, 42, 62, 1);
    text-transform: lowercase;
    height: 23px;
    display: flex;
    align-items: center;
  }

  .icon {
    margin: 0;
  }
}

.section-subtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;

  input {
    width: 500px;
  }

  i {
    color: white;
  }
}

.icon-arrow-top {
  rotate: 90deg;
  color: #01ac50;
  &:before {
    font-size: 12px;
    color: #01ac50;
  }
}

.faq {
  width: 100%;
  background: linear-gradient(0deg, #d4fae6, #d4fae6);
  color: #1c2a3e;
  font-weight: 700;
}

.clickable {
  cursor: pointer;
}

.table-container .table th,
.table-container .table td,
.table-card .table th,
.table-card .table td {
  border-left: 0 !important;
  border-bottom: 1px solid #a5c5d8;
  font-weight: 700;
}

.icon-title {
  display: flex;
  align-items: center;
  gap: 20px;
}

td.text-center {
  width: 50px;
  align-self: center;
  vertical-align: middle;
  padding: 0;
}

td {
  vertical-align: middle;
  font-weight: 700;
}

.checkbox-container {
  display: flex;
  align-items: center;
  gap: 10px;

  &.single {
    background: #fff;
    height: 50px;
  }
}

.video-row {
  input {
    flex: 1;
  }

  .row__label {
    width: 24px;
  }
}

.video-item {
  width: 100%;
  padding: 10px;
  gap: 10px;
  border-radius: 5px;
  background: #fff;

  .video-row {
    border: 0;
  }

  .delete-video {
    width: 74px;
    cursor: pointer;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #6087a0;
  }
}

.video__card {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.video-links {
  border-top: 1px solid #d3dbe3;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__item {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    color: #2196f3;
    cursor: pointer;
  }
}

.card__row-wrapper {
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .card__row {
    width: 100%;
  }

  .add-button {
    width: 45px;
    height: 30px;
  }
}
</style>
