<template>
  <div class="inner-container">
    <div class="section-title program orange-bg">
      <span>Гид по модулям</span>
      <div class="contact">
        <span class="text-sm">Служба поддержки</span>
        <i class="icon icon-more" />
        <div class="contact__item">+7 7172 972 242</div>
        <div class="contact__item">+7 778 021 13 17</div>
        <i class="icon icon-more" />
        <div class="contact__item">help@csi.kz</div>
      </div>
    </div>
    <div class="section-subtitle">
      <template v-if="openedModule === null">
        <!-- <b-form-input type="search" v-model="search" placeholder="Что ищете?" /> -->
        <div></div>
        <b-button
          variant="primary"
          @click="modalOpen = true"
          v-if="(user.roles.includes('gid_admin') || roles.ADMIN)"
        >
          <i class="icon icon-plus-circle" />
          Добавить
        </b-button>
      </template>
      <template v-else>
        <div>
          {{ openedModule.name }}
        </div>
      </template>
    </div>
    <!-- <div
      class="filter-container"
      v-if="user.roles && user.roles.includes('gid_admin')"
    >
      <div class="filter-actions">
        <b-button variant="primary" @click="modalOpen = true">
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.4996 19.136C15.2694 19.136 19.136 15.2694 19.136 10.4996C19.136 5.72991 15.2694 1.86328 10.4996 1.86328C5.72991 1.86328 1.86328 5.72991 1.86328 10.4996C1.86328 15.2694 5.72991 19.136 10.4996 19.136Z"
              stroke="#FFFFFF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.5 6.8359V14.1641"
              stroke="#FFFFFF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.83594 10.5H14.1641"
              stroke="#FFFFFF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span>Добавить</span>
        </b-button>
      </div>
    </div> -->
    <div class="table-container">
      <div class="b-table-sticky-header table-responsive-true">
        <table
          class="table b-table table-bordered b-table-no-border-collapse constructor-table"
        >
          <selected-module
            v-if="openedModule !== null"
            :openedModule="openedModule"
            @update:openedModule="handleUpdateOpenedModule"
            :roles="roles"
          />

          <tbody v-if="openedModule === null">
            <tr
              class="faq clickable"
              @click="openedModule = { name: 'Топ часто задаваемых вопросов' }"
            >
              <td class="text-center"><i class="icon icon-arrow-top" /></td>
              <td>
                <div class="item__title icon-title name">
                  <img src="./icons/faqIcon.svg" alt="" /> ТОП ВОПРОСОВ
                </div>
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <template v-for="item of globalArr">
              <tr
                :key="item.id"
                :class="getRowClass(item)"
                v-if="
                  ((user.roles && user.roles.includes('gid_admin')) ||
                    item.status === 'ACTIVE') &&
                  item.visible
                "
              >
                <td class="text-center">
                  <button
                    v-if="
                      globalArr &&
                      globalArr.filter(
                        (i) =>
                          +i.parents.map((e) => e.id).includes(item.id) &&
                          i.status === 'ACTIVE'
                      ).length
                    "
                    type="button"
                    class="btn btn-secondary"
                  >
                    <img
                      v-if="item.open"
                      src="../monitoring-snp/icons/open.svg"
                      alt=""
                      @click="handleClose(item)"
                    />
                    <img
                      v-else
                      src="../monitoring-snp/icons/closed.svg"
                      alt=""
                      @click="handleOpen(item)"
                    />
                  </button>
                </td>
                <td class="name">
                  <div
                    class="item__title icon-title"
                    @click="handleModuleClick(item)"
                  >
                    <img
                      v-if="item.level === 1"
                      src="./icons/regularModuleIcon.svg"
                      alt=""
                    />{{ item.name }}
                  </div>
                </td>
                <td>
                  <div class="inner-instruction-row">
                    <template v-for="file of item.file">
                      <div :key="file.name" class="item__instruction">
                        <img
                          v-if="
                            ['doc', 'docx'].includes(
                              getFileExtension(file.name)
                            )
                          "
                          src="./icons/textInstructionIcon.svg"
                          alt="Microsoft word icon"
                        />
                        <img
                          v-else-if="
                            ['xls', 'xlsx'].includes(
                              getFileExtension(file.name)
                            )
                          "
                          src="./icons/excel.svg"
                          alt="Microsoft excel icon"
                        />
                        <img
                          v-else-if="
                            ['ppt', 'pptx'].includes(
                              getFileExtension(file.name)
                            )
                          "
                          src="./icons/powerpoint.svg"
                          alt="Microsoft powerpoint icon"
                        />
                        <img
                          v-else-if="
                            ['pdf'].includes(getFileExtension(file.name))
                          "
                          src="./icons/pdf_icon.svg"
                          alt="PDF icon"
                        />
                        {{ file.name }}
                        <img
                          @click="handleGetFile(file)"
                          src="./icons/download.svg"
                          alt=""
                        />
                      </div>
                    </template>
                  </div>
                </td>
                <template>
                  <td>
                    <div class="inner-instruction-row">
                      <template v-for="(link, index) in item.videoLinks">
                        <div :key="index" v-if="link">
                          <div
                            :href="link"
                            target="_blank"
                            class="item__instruction video-instruction"
                            @click="
                              handleOpenVideo({
                                videoLink: link,
                                name: item.name,
                              })
                            "
                          >
                            <img
                              src="./icons/videoInstructionIcon.svg"
                              alt="Video icon"
                            />
                            Видеоинструкция {{ index + 1 }}
                          </div>
                        </div>
                      </template>
                    </div>
                  </td>
                </template>

                <td>
                  <div
                    v-if="user.roles && user.roles.includes('gid_admin')"
                    @click="handleEdit(item)"
                    class="text-center"
                  >
                    <img
                      class="more-actions"
                      src="../monitoring-snp/icons/3-vertical-dots.svg"
                      alt=""
                    />
                  </div>
                </td>
              </tr>
            </template>
            <tr
              v-if="
                user.roles && (user.roles.includes('gid_admin') || roles.ADMIN)
              "
              class="add-row"
              @click="toggleModal"
            >
              <td colspan="1">
                <img src="./icons/addIcon.svg" alt="Add icon" />
              </td>
              <td colspan="5">
                <span class="add-button">Добавить</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Модалка редактирования -->

    <b-modal
      v-model="modalOpen"
      @hide="handleModalClose"
      size="lg"
      centered
      hide-header
      hide-footer
    >
      <div class="modal__title">Добавить/Редактировать</div>
      <div class="modal__card">
        <template v-for="(options, index) of selectedParentOptions">
          <div :key="index" class="card__row-wrapper">
            <div class="card__row">
              <div class="row__label">{{ selectHeaders[index] }}</div>
              <multiselect
                v-model="selectedFilters[index]"
                :options="options || []"
                class="custom-multiselect"
                label="nameRu"
                @input="handleFilterChange(index)"
              />
            </div>
          </div>
        </template>

        <div v-if="selectedModule.id" class="checkbox-container">
          Вопрос
          <b-checkbox v-model="selectedModule.isQuestion"></b-checkbox>
        </div>

        <template
          v-if="selectedModule.id && selectedModule.isQuestion === true"
        >
          <div class="card__row">
            <div class="row__label">Вопрос</div>
            <textarea
              class="row__textarea"
              v-model="selectedModule.questionRu"
            />
          </div>
          <div class="card__row">
            <div class="row__label">Ответ</div>
            <textarea class="row__textarea" v-model="selectedModule.answerRu" />
          </div>
          <div class="card__row">
            <div class="row__label">Вопрос (на казахском)</div>
            <textarea
              class="row__textarea"
              v-model="selectedModule.questionKz"
            />
          </div>
          <div class="card__row">
            <div class="row__label">Ответ (на казахском)</div>
            <textarea class="row__textarea" v-model="selectedModule.answerKz" />
          </div>
        </template>

        <div
          v-if="selectedModule.id && selectedModule.isQuestion === true"
          class="checkbox-container single"
        >
          <b-checkbox v-model="selectedModule.isTopQuestion"></b-checkbox>
          Добавить в ТОП вопросов
        </div>

        <template
          v-if="selectedModule.id && selectedModule.isQuestion !== true"
        >
          <div class="card__row">
            <div class="row__label">Описание</div>
            <textarea
              class="row__textarea"
              v-model="selectedModule.description"
            />
          </div>
          <!-- <div class="card__row">
            <div class="row__label">Ссылка</div>
            <input
              type="text"
              class="row__input"
              v-model="selectedModule.videoLink"
            />
          </div> -->
        </template>

        <div
          v-if="selectedModule.id && selectedModule.isQuestion !== true"
          class="video-links"
        >
          <div class="modal__toggle">
            <b-form-checkbox v-model="selectedModule.checked" switch />
            <div>{{ selectedModule.checked ? "Скрыть" : "Показать" }}</div>
          </div>
          <template v-if="selectedModule.isQuestion !== true">
            <template v-for="file of selectedModule.file">
              <div :key="file.id" class="modal__file">
                <div class="modal__upload">
                  <img src="./icons/uploadIcon.svg" alt="Upload icon" />
                  <div @click="handleGetFile(file)">{{ file.name }}</div>
                </div>
                <div @click="handleFileDelete(file)" class="file__delete">
                  <img src="./icons/cross-icon.svg" alt="Cross icon" />
                  <span>Удалить</span>
                </div>
              </div>
            </template>
            <div v-if="!selectedModule.file.length" class="modal__file">
              <div class="modal__upload">
                <img src="./icons/uploadIcon.svg" alt="Upload icon" />
                <input
                  id="file-upload"
                  type="file"
                  @change="handleFileUpload"
                />
              </div>
            </div>
            <div v-if="selectedModule.file.length === 1" class="modal__file">
              <div class="modal__upload">
                <img src="./icons/uploadIcon.svg" alt="Upload icon" />
                <input
                  id="file-upload2"
                  type="file"
                  @change="handleFileUpload"
                />
              </div>
            </div>
          </template>
        </div>

        <template v-if="selectedModule.id && selectedModule.isQuestion">
          <div class="card__row video-row">
            <input
              type="text"
              class="row__input"
              placeholder="Ссылка на видеоинструкцию"
              v-model="selectedModule.videoLinkRu"
            />
            <!-- <div class="delete-video"></div> -->
          </div>
          <div class="card__row video-row">
            <input
              type="text"
              class="row__input"
              placeholder="Ссылка на видеоинструкцию (на казахском)"
              v-model="selectedModule.videoLinkKz"
            />
            <!-- <div class="delete-video"></div> -->
          </div>
        </template>

        <template v-if="selectedModule.id && !selectedModule.isQuestion">
          <div class="video-links">
            <div
              :key="index"
              v-for="(item, index) in selectedModule.videoLinks"
              class="video-links__item"
            >
              <img src="./icons/videoInstructionIcon.svg" alt="" />
              <div>Видеоинструкция {{ index + 1 }}</div>
            </div>
            <div @click="handleOpenVideoModal" class="video-links__item">
              <img src="./icons/add-video.svg" alt="" />
              <div>Добавить видеоинструкцию</div>
            </div>
          </div>
        </template>
      </div>

      <div class="modal__footer">
        <b-btn variant="success" @click="handleSave">Сохранить</b-btn>
        <b-btn variant="secondary" @click="handleModalClose"> Отменить </b-btn>
      </div>
    </b-modal>

    <!-- Модалка добавление видео -->

    <b-modal
      v-model="videoModalOpen"
      @hide="
        () => {
          videoModalOpen = false;
          modalOpen = true;
        }
      "
      size="lg"
      centered
      hide-header
      hide-footer
      v-if="this.selectedModule"
    >
      <div class="modal__title">Добавить видеоинструкции</div>

      <div class="modal__card video__card">
        <template v-if="selectedModule">
          <div
            v-for="(link, index) in selectedModule.videoLinks || []"
            :key="index"
            class="video-item"
          >
            <div class="card__row video-row">
              <div class="row__label">
                <img src="./icons/videoInstructionIcon.svg" alt="Video icon" />
              </div>
              <input
                type="text"
                class="row__input"
                placeholder="Ссылка на видеоинструкцию"
                v-model="selectedModule.videoLinks[index]"
              />
              <div class="delete-video" @click="deleteVideoLink(index)">
                Удалить х
              </div>
            </div>
          </div>
          <div @click="addVideoLink" class="video-links__item">
            <img src="./icons/add-video.svg" alt="" />
            <div>Добавить видеоинструкцию</div>
          </div>
        </template>
      </div>

      <div class="modal__footer">
        <b-btn
          variant="success"
          @click="
            videoModalOpen = false;
            modalOpen = true;
          "
          >Сохранить</b-btn
        >
        <b-btn
          variant="secondary"
          @click="
            videoModalOpen = false;
            modalOpen = true;
          "
        >
          Отменить
        </b-btn>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";

import SelectedModule from "./selected-module.vue";
import { mapMutations } from "vuex";
import { MonitoringSnpApi } from "../monitoring-snp/api/MonitoringSnpApi";

const baseUrl = "/api/guide";

export default {
  name: "instructions",
  components: {
    "selected-module": SelectedModule,
  },
  data() {
    return {
      globalArr: [],
      modalOpen: false,
      options: [],
      openCount: 0,
      selectedFilters: [],
      selectedParentOptions: [],
      lastFilter: {},
      selectedModule: {},
      selectHeaders: ["Модуль", "Режим", "Раздел"],
      user: {},
      fileStatus: "INITIAL",
      addedFiles: [],
      removedFiles: [],
      search: "",
      openedModule: null,
      videoModalOpen: false,
      DUMMY_VIDEO_LINKS: [
        {
          id: 1,
          title: "Видеоинструкция 1",
          link: "https://www.youtube.com/watch?v=6JYIGclVQdw",
        },
      ],
      roles: {
        ADMIN: false,
      },
      me: {},
    };
  },
  mounted() {
    this.user = this.$store.getters.user;
    this.roles.ADMIN = this.$store.getters.user.roles.includes("gid_admin");
    this.loadGlobalArr();
    this.loadChildrenModules(0);
    this.setMargin();
    this.getMe();
  },
  methods: {
    setMargin() {
      const margin =
        this.user.roles && this.user.roles.includes("gid_admin") ? 174 : 136;
      this.$root.$emit("mainContainerMargin", margin);
    },
    async loadChildrenModules(id, isModal = false) {
      try {
        const { data } = await axios.get("/api/guide/parent/modules", {
          params: { parId: id },
        });
        if (data.length) {
          if (id) {
            isModal
              ? (this.regimeModalOptions = data)
              : this.selectedParentOptions.push(data);
          } else {
            isModal
              ? (this.regimeModalOptions = data)
              : (this.selectedParentOptions = [data]);
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    handleFilterChange(index) {
      this.selectedFilters.splice(index + 1);
      this.selectedParentOptions.splice(index + 1);
      this.selectedModule =
        {
          ...this.globalArr.find(
            (item) => item.id === this.selectedFilters[index].id
          ),
        } || this.selectedFilters[index];
      this.selectedModule = {
        ...this.selectedModule,
        checked: this.selectedModule.status === "ACTIVE",
      };
      if (index === 0) this.regimeModalOption = this.selectedModule;
      this.loadChildrenModules(this.selectedFilters[index].id);
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      this.addedFiles.push(file);
      this.selectedModule = {
        ...this.selectedModule,
        file: [...this.selectedModule.file, { name: file.name }],
      };
    },
    handleFileDelete(file) {
      if (file.hasOwnProperty("id")) {
        this.removedFiles.push(file.id);
      } else {
        this.addedFiles.splice(
          this.addedFiles.findIndex((f) => f.name === file.name)
        );
      }
      this.selectedModule = {
        ...this.selectedModule,
        file: this.selectedModule.file.filter((f) => f.id !== file.id),
      };
    },
    handleEdit(item) {
      this.toggleModal();
      this.selectedFilters = [...item.parents, item];
      const promises = [];
      this.selectedFilters.forEach((parent) => {
        promises.push(
          axios
            .get("/api/guide/parent/modules", { params: { parId: parent.id } })
            .then((res) => res.data)
        );
      });
      Promise.all(promises).then((data) => {
        this.selectedParentOptions.push(...data.filter((arr) => arr.length));
      });
      this.selectedModule = { ...item, checked: item.status === "ACTIVE" };
    },
    getFileExtension(fileName) {
      const words = fileName.split(".");
      return words[words.length - 1];
    },
    handleGetFile(file) {
      axios
        .get("/api/guide/file", {
          params: { id: file.id },
          responseType: "blob",
        })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", file.name);
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => console.log(err));
    },
    handleSave() {
      if (this.selectedModule.isQuestion) {
        const questionBody = {
          moduleId: this.selectedModule.id,
          questionRu: this.selectedModule.questionRu,
          answerRu: this.selectedModule.answerRu,
          questionKz: this.selectedModule.questionKz,
          answerKz: this.selectedModule.answerKz,
          isTopQuestion: this.selectedModule.isTopQuestion,
          videoLinkRu: this.selectedModule.videoLinkRu,
          videoLinkKz: this.selectedModule.videoLinkKz,
        };

        this.addQuestion(questionBody);
      } else {
        const filesWithId = this.selectedModule.file.filter((file) =>
          file.hasOwnProperty("id")
        );
        const module = { ...this.selectedModule };
        if (this.removedFiles.length) {
          Promise.all(
            this.removedFiles.map((file) =>
              axios.delete("/api/guide/file", { params: { id: file } })
            )
          ).then(() => {
            this.globalArr = this.globalArr.map((item) =>
              item.id === module.id ? { ...module } : item
            );
            this.removedFiles = [];
          });
        }
        if (this.addedFiles.length) {
          Promise.all(
            this.addedFiles.map((file) => {
              const formData = new FormData();
              formData.append("file", file);
              return axios.post("/api/guide/file", formData, {
                params: { moduleId: this.selectedModule.id },
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              });
            })
          )
            .then((res) => {
              this.globalArr = this.globalArr.map((item) =>
                item.id === module.id
                  ? {
                      ...item,
                      file: [...filesWithId, ...res.map((item) => item.data)],
                    }
                  : item
              );
              this.addedFiles = [];
            })
            .catch((err) => console.log(err));
        }
        // const data = {
        //   module_id: this.selectedModule.id,
        //   video_link: this.selectedModule.videoLinks.join(","),
        //   description: this.selectedModule.description,
        //   status: this.selectedModule.checked ? "ACTIVE" : "INACTIVE",
        //   checked: this.selectedModule.checked,
        //   isQuestion: this.selectedModule.isQuestion,
        //   question: this.selectedModule.question,
        //   answer: this.selectedModule.answer,
        // };

        const body = {
          moduleId: this.selectedModule.id,
          videoLink: this.selectedModule.videoLinks,
          description: this.selectedModule.description,
          status: this.selectedModule.checked ? "ACTIVE" : "INACTIVE",
        };
        axios
          .post("/api/guide/module/add-info", body, {})
          .then(() => {
            this.globalArr = this.globalArr.map((item) =>
              item.id === body.moduleId
                ? {
                    ...item,
                    videoLink: data.video_link,
                    description: data.description,
                    status: data.status,
                    checked: data.checked,
                  }
                : item
            );
            this.changeChildrenStatus(
              this.globalArr.find((item) => item.id === body.moduleId)
            );
          })
          .catch((err) => console.log(err));
      }
    },
    buildGlobalArr(data, parent, level) {
      const parentItem = this.globalArr.find((item) => item.id === parent?.id);
      this.globalArr.push({
        id: data.id,
        name: data.name,
        nameRu: data.name,
        description: data.guide?.description,
        videoLink: data.guide?.videoLink,
        videoLinks: data.guide?.videoLinks,
        guide: data?.guide,
        file: data.guide?.file,
        status: data.status,
        checked: data.status === "ACTIVE",
        level,
        parent: data.children?.length > 0,
        parents: level === 1 ? [] : [...parentItem.parents, parentItem],
        parentId: parent?.id || null,
        open: false,
        visible: level === 1,
        faqQuantity: data.faqQuantity,
      });

      data.children?.forEach((child) => {
        this.buildGlobalArr(child, data, level + 1);
      });
    },
    loadGlobalArr() {
      this.globalArr = [];
      axios
        .get("/api/guide/modules")
        .then((res) => {
          res.data.children?.forEach((item) => {
            this.buildGlobalArr(item, null, 1);
          });
        })
        .catch((err) => console.log(err));
    },
    changeChildrenStatus(parent) {
      this.globalArr.forEach((item) => {
        if (item.parentId === parent.id) {
          item.status = parent.status;
          item.checked = parent.checked;

          if (item.parent) {
            this.changeChildrenStatus(item, parent.status, parent.checked);
          }
        }
      });
    },
    toggleModal() {
      if (this.modalOpen) {
        this.handleModalClose();
      } else {
        this.modalOpen = true;
      }
    },
    handleModalClose() {
      if (!this.videoModalOpen) {
        this.selectedModule = {};
        this.selectedFilters = [];
        this.selectedParentOptions = [];
        this.removedFiles = [];
        this.addedFiles = [];
        this.modalOpen = false;
        this.loadChildrenModules(0);
      }
    },
    handleOpen(item) {
      item.open = true;
      this.openCount++;

      this.globalArr.forEach((child) => {
        if (child.parentId === item.id) {
          child.visible = true;
        }
      });
    },
    handleClose(item) {
      item.open = false;
      this.openCount--;

      this.globalArr.forEach((child) => {
        if (child.parentId === item.id) {
          child.visible = false;

          if (child.parent && child.open) {
            this.handleClose(child);
          }
        }
      });
    },
    handleOpenAll() {
      this.globalArr.forEach((item) => {
        if (item.parent && !item.open) {
          this.handleOpen(item);
        }
      });
    },
    handleCloseAll() {
      this.globalArr.forEach((item) => {
        if (item.parent && item.open && item.level === 1) {
          this.handleClose(item);
        }
      });
    },
    handleUpdateOpenedModule(value) {
      this.openedModule = value;
    },
    ...mapMutations({
      setVideoPlayerState: "setVideoPlayerState",
    }),
    embedUrl(link) {
      const url = new URL(link);
      const videoId = url.searchParams.get("v");
      return `https://www.youtube.com/embed/${videoId}`;
    },
    handleOpenVideo(item) {
      this.setVideoPlayerState({
        isOpen: true,
        src: item.videoLink,
        embedSrc: this.embedUrl(item.videoLink),
        name: item.name,
      });
    },
    handleOpenVideoModal() {
      this.videoModalOpen = true;
    },
    handleAddToSelect({ name, options }) {
      if (name === "Модуль") {
        this.moduleModalOpen = true;
      } else {
        this.regimeModalOpen = true;
        this.regimeModalOption = this.selectedFilters[0];
        this.loadChildrenModules(this.selectedFilters[0].id, true);
      }
    },
    getFaqList() {
      MonitoringSnpApi.get(`${baseUrl}/faq`).then((res) => {
        // console.log(res, "res");
        return;
      });
    },
    addQuestion(body) {
      MonitoringSnpApi.post(`${baseUrl}/faq`, body).then(() => {
        this.handleModalClose();
      });
    },
    handleModuleClick(item) {
      if (item.faqQuantity !== 0) {
        this.openedModule = item;
      }
    },
    getRowClass(item) {
      let cls = "";
      item.status === "ACTIVE"
        ? this.user.roles && this.user.roles.includes("gid_admin")
          ? (cls += "tr-level--1")
          : (cls += `tr-level--${item.level}`)
        : (cls += "tr-level--3");

      cls = item.faqQuantity > 0 ? cls + " clickable" : `${cls} c-d`;

      return cls;
    },
    getMe() {
      MonitoringSnpApi.get("/snp/api/v1/me")
        .then((res) => {
          this.me = res.data;
        //   this.roles.ADMIN = res.data.roles.includes("ROLE_SNP_ADMIN") || this.user.roles.includes('gid_admin') || res.data.roles.includes('ROLE_gid_admin');
        })
        .catch(() => {});
    },
    addVideoLink() {
      if (!this.selectedModule.videoLinks) {
        this.$set(this.selectedModule, "videoLinks", []);
      }
      this.selectedModule.videoLinks.push("");
    },
    deleteVideoLink(index) {
      this.selectedModule.videoLinks.splice(index, 1);
    },
  },
};
</script>

<style>
.instructions-container {
  margin-top: 180px;
  height: calc(100vh - 180px);
}
</style>
<style lang="scss" scoped>
.filter-container {
  justify-content: flex-end;
  svg {
    margin-right: 12px;
  }
}

.tooltip {
  &__actions {
    padding: 0 5px;
  }

  &__action {
    padding: 3px 3px 3px 5px;
    text-align: left;
    cursor: pointer;

    &--edit {
      border-bottom: 1px solid #eef1f8;
      color: #223345;
    }

    &--delete {
      color: #c0504c;
    }
  }
}

.item__instruction {
  display: flex;
  align-items: center;
  gap: 10px;
  box-shadow: 3px 3px 3px 0px #0000001a;
  width: max-content;
  padding: 10px 15px;
  background: #f7f9fc;
  margin: 5px;
  border-radius: 5px;
  img {
    width: 20px;
    height: 20px;

    &:last-child {
      cursor: pointer;
    }
  }

  &.video-instruction {
    cursor: pointer;
    color: #2196f3;
    text-decoration: underline;
  }
}

.table-container .table {
  & .add-row {
    background-color: #f7f9fc;
    cursor: pointer;
  }

  .tr-level {
    &--1 {
      background-color: #e7f6ff;

      .item__title {
        text-transform: uppercase;
        font-weight: 700;
      }
    }

    &--2 {
      background-color: #fff;

      .item__title {
        font-weight: 500;
      }
    }

    &--3 {
      background-color: #fff;

      .item__title {
        font-weight: 400;
      }
    }
  }

  & td {
    // max-width: 450px;
    min-height: 78px;

    &:last-child,
    &:first-child {
      vertical-align: middle;
    }
  }
}

.add-button {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.4;
}

.more-actions {
  cursor: pointer;
  width: 4px;
  margin-top: auto;
}

.modal {
  &__card {
    margin-top: 15px;
    background-color: #f7f9fc;
    border-radius: 5px;
    padding: 15px 20px 0;
    border: 1px solid #e2e5eb;
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 1.4;
  }

  &__toggle {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 15px;
  }

  &__file {
    display: flex;
    gap: 20px;
    align-items: center;
    font-size: 14px;
    color: #6087a0;
  }

  &__upload {
    padding: 0 20px;
    display: flex;
    align-items: center;
    gap: 13px;

    & img {
      width: 10px;
      height: 18px;
    }

    & a {
      font-size: 14px;
      color: #6087a0;
      text-decoration: underline;
    }
  }

  &__footer {
    padding-top: 15px;
    display: flex;
    gap: 20px;

    button {
      margin-left: 0;
    }
  }
}

.card {
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 0;

    :has(> textarea) {
      align-items: flex-start;
    }

    & > *:last-child {
      width: 440px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #41618033;
    }
  }
}

.row {
  &__textarea {
    height: 110px;
    resize: none;
  }

  &__input {
    border: 1px solid #bcd2e0;
    border-radius: 4px;
    font-size: 14px;
    padding: 4px 8px;
  }
}

.file {
  &__delete {
    cursor: pointer;
    color: #6087a0;
    display: flex;
    gap: 10px;
  }
}

.orange-bg {
  background: rgba(247, 150, 71, 1);
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 700;
}

.contact {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 500;

  &__item {
    background: rgba(255, 255, 255, 1);
    border-radius: 5px;
    border: 1px solid rgba(224, 130, 54, 1);
    padding: 0px 10px;
    color: rgba(28, 42, 62, 1);
    text-transform: lowercase;
    height: 23px;
    display: flex;
    align-items: center;
  }

  .icon {
    margin: 0;
  }
}

.section-subtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;

  input {
    width: 500px;
  }

  i {
    color: white;
  }
}

.icon-arrow-top {
  rotate: 90deg;
  color: #01ac50;
  &:before {
    font-size: 12px;
    color: #01ac50;
  }
}

.faq {
  width: 100%;
  background: linear-gradient(0deg, #d4fae6, #d4fae6);
  color: #1c2a3e;
  font-weight: 700;
}

.clickable {
  .name {
    cursor: pointer;
  }
}

.table-container .table th,
.table-container .table td,
.table-card .table th,
.table-card .table td {
  border-left: 0 !important;
  border-bottom: 1px solid #a5c5d8;
  font-weight: 700;
}

.icon-title {
  display: flex;
  align-items: center;
  gap: 20px;
}

td.text-center {
  width: 50px;
  align-self: center;
  vertical-align: middle;
  padding: 0;
}

td {
  vertical-align: middle;
  font-weight: 700;
}

.checkbox-container {
  display: flex;
  align-items: center;
  gap: 10px;

  &.single {
    background: #fff;
    height: 50px;
  }
}

.video-row {
  input {
    flex: 1;
  }

  .row__label {
    width: 24px;
  }
}

.video-item {
  width: 100%;
  padding: 10px;
  gap: 10px;
  border-radius: 5px;
  background: #fff;

  .video-row {
    border: 0;
  }

  .delete-video {
    width: 74px;
    cursor: pointer;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #6087a0;
  }
}

.video__card {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.video-links {
  border-top: 1px solid #d3dbe3;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__item {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    color: #2196f3;
    cursor: pointer;
  }
}

.card__row-wrapper {
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .card__row {
    width: 100%;
  }

  .add-button {
    width: 45px;
    height: 30px;
  }
}

.c-d {
  cursor: default;
}

.inner-instruction-row {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
</style>
